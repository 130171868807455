import React from "react"
import Layout from '@components/layout/layout.jsx'
import DocumentHeadMeta from '@components/utility/SEO.jsx'
import { StaticImage, getImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
//Components
import HomeHero from '@components/homeHero/homeHero.jsx'
import Portrait from '@components/portrait/portrait.jsx'


// markup
const IndexPage = ({ data }) => {
  const GabriellePortrait = getImage(data.portrait)
  return (
    <Layout addedClass="page--home">
      <DocumentHeadMeta title='ALL IN&reg; for Lupus Nephritis | LN Support Community' description='The ALL IN&reg; community informs and empowers people living with lupus nephritis. Download resources and sign up to receive updates on community information about lupus nephritis.' canonicalURL='https://www.allinforlupusnephritis.com/' />
      <HomeHero></HomeHero>
      <section className="bg-light-purple">
        <div className="container">
          <StaticImage src="../images/home-hands-icon.png" className="home-hands-icon" quality="90" placeholder="NONE" alt="" width={116} ></StaticImage>
          <p>The ALL IN&reg; community informs and empowers people living with lupus nephritis, and those with lupus looking to learn more.</p>
          <Link to="/register" className="cta--purple cta--body cta--body--extra-wide">Join the ALL IN community</Link>
        </div>
      </section>
      <section className="bg-white">
        <div className="container container--narrow">
          <p className="text-left">Lupus nephritis is a serious and common complication of lupus that impacts the <span className="nowrap">kidneys&mdash;about</span> 1 in 2&nbsp;people living with lupus may go on to develop it. Wherever you are in your journey, you're in the right place. The ALL IN&reg; community is a source of information, resources, and support for people living with or at risk for lupus nephritis (LN) and their care partners. We're here to help strengthen your support system and arm you with the knowledge you need to take action.</p>
        </div>
      </section>
      <section className="bg-light-purple">
        <div className="container container--narrow">
          <Portrait name="Gabrielle" description="Diagnosed with lupus nephritis in 2009" imgSrc={GabriellePortrait} imgAlt=""></Portrait>
          <p>"There's not a lot that we can control, but <strong>when you feel like you're doing the very best to take care of you, you feel empowered</strong> and it gives you more hope to live a good life with this disease."</p>
        </div>
      </section>
      <section className="bg-white">
        <div className="container">
          <StaticImage src="../images/what-is-ln-icon.png" className="what-is-ln-icon" quality="80" placeholder="NONE" alt="" width={205} ></StaticImage>
          <h2>What is lupus nephritis?</h2>
          <p className="less-margin">Lupus nephritis is a serious yet common complication of lupus, in which the immune system attacks the healthy cells of the kidneys and causes inflammation.</p>
          <Link to="/about-lupus-nephritis" className="cta--purple cta--body">Learn more</Link>
        </div>
      </section>
      <section className="bg-light-blue">
        <div className="container container--split">
          <div className="split-item">
            <StaticImage src="../images/appointment-icon.png" className="split-item__icon" quality="90" placeholder="NONE" alt="" width={214}></StaticImage>
            <h2>Make the most of your appointments.</h2>
            <p>Bring this discussion guide to help you navigate your next doctor visit.</p>
            <Link to="/resources" className="cta--purple cta--body">Download guide</Link>
          </div>
          <div className="divider"></div>
          <div className="split-item">
            <StaticImage src="../images/stay-connected-icon.png" className="split-item__icon" quality="90" placeholder="NONE" alt="" width={216}></StaticImage>
            <h2>Stay connected <br className="desktop-only" />with us.</h2>
            <p>Sign up to receive information about lupus nephritis, community events, and health and wellness tips.</p>
            <Link to="/register" className="cta--purple cta--body cta--body--wide">Join the ALL IN community</Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query PortraitQuery {
    portrait: file(relativePath: {eq: "portrait-Gabrielle.png"}) {
      childImageSharp {
        gatsbyImageData(width:240)
      }
    }
  }
`
